import * as React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import Layout from 'spartacus/components/Layout'
import Typography from 'spartacus/components/Typography'
import Button from 'spartacus/components/Button'
import Colors from 'spartacus/styles/colors'

const LinkContainer = styled.div`
  a:hover {
    color: ${Colors.UI.White};
  }
`

const FourZeroFourPage = (): JSX.Element => (
  <Layout variant="ancillary">
    <Typography element="h1" kind="TS4 - Strong">
      404
    </Typography>
    <Typography element="p">Looks like that page doesn’t exist.</Typography>
    <LinkContainer>
      <Link href="/" passHref>
        <Button>Go Home</Button>
      </Link>
    </LinkContainer>
  </Layout>
)

export default FourZeroFourPage
